import { ArrowDownSvg, CodeGenerationSvg, PlusBlueSvg } from 'assets/svg-icon';
import classNames from 'classnames';
import { listAiBot } from 'CONST/listAiBot';
import { useSelect } from 'hooks/useSelect';
import { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { Input } from 'ui/Input';
import { PopUpWrapper } from 'ui/PopUpWrapper/PopUpWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { addBotPromoRequest } from 'redux/reducers/ai-chat-bot/reducer';
import { IAiChatBotsResponse } from 'redux/reducers/ai-chat-bot/types';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'ui/DatePicker/DatePicker';
import { InfiniteScrollCodeGeneration } from 'ui/InfiniteScroll/InfiniteScroll';
import useOutsideClick from 'hooks/useOutsideClick';

import { getCodeLoaderSelector } from 'redux/reducers/ai-chat-bot/selectors';

interface IProps {
	onCreateBot: (nameBot: MouseEvent) => void;
	botsList: null | IAiChatBotsResponse;
	param: string;
	search: string;
	partners: Array<string> | [];
	loading: boolean;
	onOpen: boolean;
	setSearch: (value: string) => void;
	setPerPage: (value: number) => void;
	setOnOpen: (value: boolean) => void;
}

export const CreateBotList: FC<IProps> = ({
	setSearch,
	search,
	param,
	onCreateBot,
	botsList,
	setPerPage,
	loading,
	partners,
	setOnOpen,
	onOpen,
}) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const [checkBox, setCheckBox] = useState<string>('');
	const [popup, setPopup] = useState<boolean>(false);
	const [popupNext, setPopupNext] = useState<boolean>(false);
	const [numberOfCodes, setNumberOfCodes] = useState<number>(0);
	const [nameCode, setNameCode] = useState<string>('');
	const [partner, setPartner] = useState<string>('');
	const [partnerList, setPartnerList] = useState<Array<string>>(partners);
	const [codeLife, setCodeLife] = useState<boolean>(false);
	const [codeLifeNumber, setCodeLifeNumber] = useState<number>(0);
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const ref = useRef<HTMLDivElement | null>(null);
	const codeLoader = useSelector(getCodeLoaderSelector);

	useOutsideClick(ref, () => setOnOpen(false));

	const showAll = () => {
		setPartner('');
		setPartnerList(partners);
	};

	const resetInput = () => {
		setCodeLife(false);
		setNameCode('');
		setNumberOfCodes(0);
		setSearch('');
		setCheckBox('');
		setPartner('');
	};

	const handlInput = (event: ChangeEvent) => {
		const { value, name } = event.target as HTMLInputElement;
		name === 'number_of_codes' && setNumberOfCodes(Number(value));
		name === 'name_code' && setNameCode(value);
		name !== 'number_of_codes' && name !== 'name_code' && setSearch(value);
	};

	const handlPartner = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setPartnerList(
			partners.filter((partner) => partner.toLowerCase().includes(value.toLowerCase())),
		);
		setPartner(value);
	};

	const onClose = () => {
		setPopup(!popup);
		setCheckBox('');
		setNumberOfCodes(0);
		setNameCode('');
	};

	const onCloseNextPpup = () => {
		setPopupNext(!popupNext);
		setCheckBox('');
		setNumberOfCodes(0);
		setNameCode('');
	};

	const onChange = (event: ChangeEvent) => {
		const { name } = event.currentTarget as HTMLInputElement;

		setCheckBox(name);
	};

	const handleSelect = (partner: string) => {
		setOnOpen(false);
		setPartner(partner);
	};

	const onNextPopup = () => {
		setPopup(false);
		setPopupNext(true);

		popupNext &&
			dispatch(
				addBotPromoRequest({
					data: {
						bot_id: Number(checkBox),
						count_codes: numberOfCodes,
						label: nameCode,
						life_time_days: Number(codeLifeNumber),
						partner,
					},
					closePopup: setPopupNext,
					navigation,
					param,
					resetInput,
				}),
			);
	};

	return (
		<>
			<div className="add-btn-wrap">
				<button
					onClick={() => setPopup(true)}
					style={{
						display: 'flex',
						gap: '5px',
						background: '#fff',
						color: '#0A49A5',
						border: '1px solid #CFCFD2',
					}}
					className="add-btn"
					type="button"
				>
					<CodeGenerationSvg />
					Code generation
				</button>
				<button
					ref={triggerRef}
					onClick={toggleOpen}
					style={{
						display: 'flex',
						gap: '5px',
						background: '#fff',
						color: '#0A49A5',
						border: '1px solid #CFCFD2',
					}}
					className="add-btn"
					type="button"
				>
					<PlusBlueSvg />
					Create new AI Bot
				</button>
				{open ? (
					<div className="">
						<div className={classNames('select', { active: open })}>
							<div
								className="select__drop select__position select__drop-custom ai-bot-wrap"
								ref={dropRef}
							>
								<div className="select__drop-scroll">
									<div className="select__drop-item">
										<ul className="ai-bot-list">
											{listAiBot.map((item, key) => (
												<li
													id={item.replaceAll(' ', '_')}
													onClick={(e) => onCreateBot(e)}
													key={key}
													className="countries-list ai-bot-item"
												>
													{item}
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
			{popup && (
				<InfiniteScrollCodeGeneration
					onNextPopup={onNextPopup}
					search={search}
					handlInput={handlInput}
					botsList={botsList}
					onClose={onClose}
					checkBox={checkBox}
					onChange={onChange}
					setPerPage={setPerPage}
					loadingAI={loading}
				/>
			)}
			{popupNext && (
				<PopUpWrapper
					handleButton={onNextPopup}
					disablet={codeLoader ? false : numberOfCodes && nameCode && codeLifeNumber ? 'true' : ''}
					isOpenModal={onCloseNextPpup}
					title={'Code generation'}
					btnName={'Approve'}
					btnNameTwo={'Reject'}
				>
					<Input
						handleInput={handlInput}
						value={String(numberOfCodes)}
						type="number"
						name="number_of_codes"
						placeholder={'Number'}
						label={'Number of codes'}
						className="padding-bottom"
					/>
					<div className="select-custom">
						<div ref={ref} className={classNames('select', { active: onOpen })}>
							<div
								style={{ width: '100%' }}
								onClick={() => setOnOpen(true)}
								onFocus={() => setOnOpen(true)}
							>
								<Input
									handleInput={handlPartner}
									value={partner}
									type="text"
									name="number_of_codes"
									placeholder={'Partner'}
									label={'Partner'}
									className="padding-bottom"
								/>
							</div>

							<div
								style={{
									right: '-28px',
									left: 0,
									top: '76px',
									maxWidth: '340px',
									maxHeight: '250px',
									overflow: 'hidden',
									padding: 0,
								}}
								className="select__drop select__drop-custom select__drop-custom-country"
							>
								<div className="select__drop-scroll select__drop-scroll-custom">
									<div className="select__drop-item">
										<ul>
											<li className="countries-list">
												<button onClick={showAll} type="button">
													Clean up
												</button>
											</li>
											{partnerList.map((item) => (
												<li className="countries-list" key={item}>
													<button onClick={() => handleSelect(item)} type="button">
														{item}
													</button>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="button-inner">
						<span style={{ marginTop: '50px' }} className="button-label">
							Codes life
						</span>
						<button onClick={() => setCodeLife(!codeLife)} className="button-select" type="button">
							<span>Codes life</span>
							<div className="button-arrow">
								<ArrowDownSvg />
							</div>
						</button>
					</div>
					{codeLife ? <DatePicker setCodeLifeNumber={setCodeLifeNumber} /> : null}
					<Input
						handleInput={handlInput}
						value={nameCode}
						type="text"
						name="name_code"
						placeholder={'Name'}
						label={'Name'}
						className="padding-bottom"
					/>
				</PopUpWrapper>
			)}
		</>
	);
};
