import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAdminsChannelsResponse,
	IAdminsChannelsSaga,
	IChannels,
	IChannelsRequest,
	IChannelsResponse,
	IChannelsStore,
	ICommentsResponse,
	ICommentsSagaRequest,
	IDeactivateSagaRequest,
	IDeletePost,
	IDeleteSagaRequest,
	IDetailsChannel,
	IMediaFilesListRequest,
	IMediaFilesListResponse,
	IMediaFilesRequest,
	IMediaFilesResponse,
	IMediaSalesResponse,
	IMediaSalesSaga,
	IMembersResponse,
	IMembersSaga,
	IMoveTopRequest,
	IPostRequest,
	IPostResponse,
	IPostsResponse,
	IPostsSaga,
	IRecommendedRequest,
	IStatisticRequest,
	IStatisticResponse,
	ITopCannelRequest,
	IVerifiedREquest,
} from './types';

import { arrayId, mocData } from 'CONST/channels-tabs';

export const initialState: IChannelsStore = {
	channelsList: null,
	topChannelList: null,
	channelsLoader: false,
	statistics: null,
	detailsChannel: null,
	admins: null,
	members: null,
	mediaSails: null,
	posts: null,
	post: null,
	comments: null,
	mediaFiles: null,
	mediaFile: null,
};

const channels = createSlice({
	name: '@@channels',
	initialState,
	reducers: {
		channelsListRequest: (state, action: PayloadAction<IChannelsRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		channelsListSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.channelsList = payload;
		},

		getStatisticRequest: (state, action: PayloadAction<IStatisticRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getStatisticSuccess: (state, action: PayloadAction<IStatisticResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.statistics = payload;
		},

		getDetailsChannelRequest: (state, action: PayloadAction<string>) => {
			const messagesState = state;
			messagesState.channelsLoader = true;
		},

		getDetailsChannelSuccess: (state, action: PayloadAction<IDetailsChannel>) => {
			const { payload } = action;
			const messagesState = state;
			messagesState.detailsChannel = payload;
		},

		changeVerifiedRequest: (state, action: PayloadAction<IVerifiedREquest>) => {},

		changeVerifiedSuccess: (state, action: PayloadAction<IVerifiedREquest>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.channelsList?.channels.forEach((channel) => {
				if (channel.id === payload.channel_id) {
					channel.verified = payload.verify;
				}
			});
		},

		changeRecommendedRequest: (state, action: PayloadAction<IRecommendedRequest>) => {},

		changeRecommendedSuccess: (state, action: PayloadAction<IRecommendedRequest>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.channelsList?.channels.forEach((channel) => {
				if (channel.id === payload.channel_id) {
					channel.recommended = payload.recommend;
				}
			});
		},

		blockChannelsRequest: (state, action: PayloadAction<IDeactivateSagaRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		blockChannelsSuccess: (state, action: PayloadAction<any>) => {
			const channelsState = state;
			const { payload } = action;
			channelsState.channelsLoader = false;
			// channelsState.userDetails = payload;
		},

		deleteChannelsRequest: (state, action: PayloadAction<IDeleteSagaRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		deleteChannelsSuccess: (state) => {
			const channelsState = state;
			channelsState.channelsLoader = false;
		},

		getAdminsChannelsRequest: (state, action: PayloadAction<IAdminsChannelsSaga>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getAdminsChannelsSuccess: (state, action: PayloadAction<IAdminsChannelsResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.admins = payload;
		},

		getMembersRequest: (state, action: PayloadAction<IMembersSaga>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getMembersSuccess: (state, action: PayloadAction<IMembersResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.members = payload;
		},

		getMediaSalesRequest: (state, action: PayloadAction<IMediaSalesSaga>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getMediaSalesSuccess: (state, action: PayloadAction<IMediaSalesResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.mediaSails = payload;
		},

		getPostsRequest: (state, action: PayloadAction<IPostsSaga>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getPostsSuccess: (state, action: PayloadAction<IPostsResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.posts = payload;
		},

		getPostRequest: (state, action: PayloadAction<IPostRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getPostSuccess: (state, action: PayloadAction<IPostResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.post = payload;
		},

		getPostCommentsRequest: (state, action: PayloadAction<ICommentsSagaRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getPostCommentsSuccess: (state, action: PayloadAction<ICommentsResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.comments = payload;
		},

		deletePostRequest: (state, action: PayloadAction<IDeletePost>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		deletePostSuccess: (state, action: PayloadAction<IPostRequest>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.channelsLoader = false;
			// channelsState.post = payload;
		},

		getMediaFilesRequest: (state, action: PayloadAction<IMediaFilesRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getMediaFilesSuccess: (state, action: PayloadAction<IMediaFilesResponse | null>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.mediaFile = payload;
		},

		postMediaFilesRequest: (state, action: PayloadAction<IMediaFilesListRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		postMediaFilesSuccess: (state, action: PayloadAction<IMediaFilesListResponse>) => {
			const { payload } = action;
			const channelsState = state;
			channelsState.mediaFiles = payload;
		},

		getTopChannelsRequest: (state) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		getTopChannelsSuccess: (state, action: PayloadAction<IChannelsResponse>) => {
			const { payload } = action;
			const channelsState = state;

			//@ts-ignore
			const result: Array<IChannels> = [...Array(10 - payload.channels.length).keys()].map((i) => ({
				...mocData,
				id: arrayId[i],
			}));

			channelsState.topChannelList = [...payload.channels, ...result];
		},

		addTopChannelsRequest: (state, action: PayloadAction<ITopCannelRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		addTopChannelsSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const channelsState = state;
			let index: number | undefined = undefined;

			channelsState.topChannelList?.find((top, i) => {
				if (String(top.id).includes('empty') && !index) {
					index = i;
				}
			});

			if (channelsState.topChannelList)
				channelsState.topChannelList[index || 9] = channelsState.channelsList?.channels.find(
					(channel) => channel.id === payload,
				) as IChannels;
		},

		moveTopChannelsRequest: (state, action: PayloadAction<IMoveTopRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		moveTopChannelsSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const channelsState = state;

			// channelsState.topChannelList = payload;
		},

		removeTopChannelsRequest: (state, action: PayloadAction<ITopCannelRequest>) => {
			const channelsState = state;
			channelsState.channelsLoader = true;
		},

		removeTopChannelsSuccess: (state, action: PayloadAction<number>) => {
			const { payload } = action;
			const channelsState = state;
			if (channelsState.topChannelList)
				channelsState.topChannelList = channelsState.topChannelList.map((channel, index) =>
					channel.id === payload ? { ...mocData, id: arrayId[index] } : channel,
				);
		},

		channelsInitState: (state) => {
			const channelsInitState = state;
			channelsInitState.channelsLoader = false;
		},
	},
});

export default channels.reducer;
export const {
	channelsListRequest,
	channelsListSuccess,
	getStatisticRequest,
	getStatisticSuccess,
	getDetailsChannelRequest,
	getDetailsChannelSuccess,
	changeVerifiedRequest,
	changeVerifiedSuccess,
	changeRecommendedRequest,
	changeRecommendedSuccess,
	blockChannelsRequest,
	blockChannelsSuccess,
	deleteChannelsRequest,
	deleteChannelsSuccess,
	getAdminsChannelsRequest,
	getAdminsChannelsSuccess,
	getMembersRequest,
	getMembersSuccess,
	getMediaSalesRequest,
	getMediaSalesSuccess,
	getPostsRequest,
	getPostsSuccess,
	getPostRequest,
	getPostSuccess,
	getPostCommentsRequest,
	getPostCommentsSuccess,
	deletePostRequest,
	deletePostSuccess,
	getMediaFilesRequest,
	getMediaFilesSuccess,
	postMediaFilesRequest,
	postMediaFilesSuccess,
	getTopChannelsRequest,
	getTopChannelsSuccess,
	addTopChannelsRequest,
	addTopChannelsSuccess,
	removeTopChannelsRequest,
	removeTopChannelsSuccess,
	moveTopChannelsRequest,
	moveTopChannelsSuccess,
	channelsInitState,
} = channels.actions;
