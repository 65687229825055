import { ChangeEvent, FC, useEffect, useState } from 'react';
import { IAdvertiseList, ITemplateText } from 'redux/reducers/ai-chat-bot/types';
import { IEntities, TextEditor } from 'ui/TextEditor/TextEditor';
import TimeZoneFilter from '../DatePicker/DatePicker';
import { DropFile } from 'ui/DropFile/DropFile';
import { DownloadSvg } from 'assets/svg-icon';
import Remove from '../../../../assets/svg-icon/remove.svg';
import { Avatar } from 'ui/Avatar/Avatar';
import { getDateAndTime } from 'untils/getDateAndTime';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteAdvertiseImageRequest,
	deleteAdvertiseRequest,
	uploadAdvertiseImageRequest,
} from 'redux/reducers/ai-chat-bot/reducer';
import { useParams } from 'react-router-dom';
import { PopUpAsk } from 'ui/PopUpAsk/PopUpAsk';
import '../AddNewTemplate/AddNewTemplate.scss';
import { maxFileSize } from 'untils/maxFileSize';
import { IData } from 'pages/Dashboard/EditAiChatBot/EditAiChatBot';
import { Video } from 'ui/Video/Video';
import { SmallLoader } from 'ui/SmallLoader';
import { getAiLoaderSelector } from 'redux/reducers/ai-chat-bot/selectors';

interface IProps {
	templates: Array<IAdvertiseList>;
	advertise: IAdvertiseList;
	nameFile?: string;
	text: string;
	loader?: boolean;
	editAdvertise: any;
	datAdvertise: IData;
	isModified: boolean;
	onSaveEdit: () => void;
}

export const Accordion: FC<IProps> = ({
	onSaveEdit,
	isModified,
	datAdvertise,
	editAdvertise,
	advertise,
	nameFile,
	loader,
	text,
}) => {
	const [description, setDescription] = useState(advertise.title);
	const [entities, setEntities] = useState<Array<IEntities> | []>(advertise.entities);
	const [files, setFiles] = useState<Array<File | { access_hash: string; is_video: boolean }>>(
		advertise?.medias?.map((img) => img) || [],
	);
	const dispatch = useDispatch();
	const params = useParams();
	const [open, setOpen] = useState<string>('');
	const aiLoader = useSelector(getAiLoaderSelector);

	const onDelete = (file: any) => {
		setFiles((prev) =>
			prev.filter((item: any) => {
				if (item?.name && file?.name) {
					return item.name !== file.name;
				}
				if (item.access_hash && file?.access_hash) {
					file.access_hash === item.access_hash &&
						dispatch(
							deleteAdvertiseImageRequest({
								bot_id: params.id || '',
								id: String(advertise.advertise_id),
								hash: file.access_hash,
							}),
						);
					return item.access_hash !== file.access_hash;
				}

				return item;
			}),
		);
	};

	const handleChange = (newFiles: File, name: string) => {
		if (newFiles.size >= maxFileSize(100)) {
			alert('The file exceeds the maximum size of 100 MB, so it has been rejected.');
			return;
		}
		const formData = new FormData();
		formData.append('file', newFiles);

		dispatch(
			uploadAdvertiseImageRequest({
				bot_id: params.id || '',
				id: String(advertise.advertise_id),
				body: formData,
			}),
		);
		files?.length ? setFiles((prev) => [...prev, newFiles]) : setFiles([newFiles]);
	};

	const deleteTemplate = () => {
		dispatch(deleteAdvertiseRequest({ setOpen, data: { bot_id: params.id, id: open } }));
	};

	useEffect(() => {
		editAdvertise({ entities, title: description, advertise_id: advertise.advertise_id });
	}, [description, entities]);

	console.log(files);

	return (
		<div className="accordion">
			{open ? (
				<PopUpAsk
					setText={setOpen}
					title={`Are you sure you want to delete this ${params.tab
						?.replace('-', ' ')
						.replace('s', ' ')}?`}
					btnNameOne="Yes, I’m sure"
					btnNameTwo="Cancel"
					onClick={deleteTemplate}
				/>
			) : null}
			<div
				style={{ gridTemplateColumns: '0.6fr 1fr' }}
				className={`accordion__box${text ? `-${text}` : ''}`}
			>
				<div className="accordion__left-content">Title</div>
				<div className="add-new-template-popup__editor">
					<TextEditor
						setDescription={setDescription}
						defaultText={description}
						setEntities={setEntities}
						entities={entities}
						defaultEntities={advertise.entities}
					/>
				</div>
			</div>
			<div
				style={{ gridTemplateColumns: '0.6fr 1fr' }}
				className={`accordion__box${text ? `-${text}` : ''}`}
			>
				<div className="accordion__left-content">Offer Validity Period</div>
				<TimeZoneFilter
					gmto={advertise.time_zone}
					dateAndTimeStart={advertise.time_from}
					dateAndTimeEnd={advertise.time_to}
					editAdvertise={editAdvertise}
				/>
			</div>
			<div
				style={{ gridTemplateColumns: '0.6fr 1fr' }}
				className={`accordion__box${text ? `-${text}` : ''}`}
			>
				<div className="accordion__left-content">Upload file</div>
				<div>
					{files?.length ? (
						<div className="image-preview">
							{files?.map((file: any, index) => (
								<div
									className="image-preview-wrap"
									style={{
										overflow: 'hidden',
										display: 'flex',
										alignItems: 'center',
										position: 'relative',
										width: '135px',
										height: '135px',
										margin: '5px',
									}}
									key={index}
								>
									<button onClick={() => onDelete(file)} type="button" className="btn-remove">
										<img src={Remove} alt="remove button" />
									</button>
									{file?.access_hash ? (
										file.is_video ? (
											<Video
												type={file.is_video ? 'video' : ''}
												hash={String(file.access_hash)}
												endpoint={file.is_video ? '/storage/document' : '/storage/photo'}
											/>
										) : (
											<Avatar
												type={file.is_video ? 'video' : ''}
												hash={String(file.access_hash)}
												endpoint={file.is_video ? '/storage/document' : '/storage/photo'}
											/>
										)
									) : file.type.includes('video') ? (
										<div className="wrap-content">
											<video muted controls autoPlay>
												<source src={URL.createObjectURL(file)} type="video/mp4" />
											</video>
											{aiLoader ? (
												<span>
													<SmallLoader />
												</span>
											) : null}
										</div>
									) : (
										<div className="wrap-content">
											<img
												className="ava"
												style={{ width: '100%', maxWidth: '135px', maxHeight: '135px' }}
												src={URL.createObjectURL(file)}
												alt={`Preview ${index + 1}`}
											/>
											{aiLoader ? (
												<span>
													<SmallLoader />
												</span>
											) : null}
										</div>
									)}
								</div>
							))}
						</div>
					) : null}
					{files?.length < 10 ? (
						<div className="accordion__right-content">
							<div style={{ alignItems: 'flex-start' }} className="reactions-add__input-wrapper">
								<DropFile
									photo={''}
									svg={<DownloadSvg />}
									size={0}
									nameFile={nameFile}
									handleChange={(file: any) => handleChange(file, '')}
									loader={loader}
								/>
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div className="button-delete-wrap">
				<button
					onClick={() => setOpen(String(advertise.advertise_id))}
					className="button-delete"
					type="button"
				>
					DELETE
				</button>
				<button
					disabled={!isModified}
					onClick={onSaveEdit}
					className="button-recomendation-save"
					type="button"
				>
					SAVE
				</button>
			</div>
		</div>
	);
};
