//@ts-nocheck
import React, { useRef, useEffect, useState, FC } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';
import 'quill-emoji';

export interface IEntities {
	type?: string;
	offset?: number;
	length?: number;
	url?: string;
}

interface PropsTextEditor {
	setEntities: (value: Array<IEntities> | []) => void;
	entities: Array<IEntities> | [];
	defaultText: string;
	setDescription: (value: string) => void;
	defaultEntities: Array<IEntities> | [];
}

export const TextEditor: FC<PropsTextEditor> = ({
	defaultText,
	setEntities,
	entities,
	defaultEntities,
	setDescription,
}) => {
	const quillRef = useRef<HTMLDivElement | null>(null);
	const [quillInstance, setQuillInstance] = useState<Quill | null>(null);

	useEffect(() => {
		if (!quillRef.current || quillInstance) return;

		Quill.register('modules/emoji', require('quill-emoji').default);

		// Реєстрація додаткових форматів
		// Quill.register('formats/bold', Quill.import('formats/bold'));
		// Quill.register('formats/italic', Quill.import('formats/italic'));
		// Quill.register('formats/underline', Quill.import('formats/underline'));
		// Quill.register('formats/link', Quill.import('formats/link'));

		const quill = new Quill(quillRef.current, {
			theme: 'snow',
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'link'],
					[{ list: 'ordered' }, { list: 'bullet' }],
					// ['emoji'],
				],
				// 'emoji-toolbar': true,
				// 'emoji-shortname': true,
			},
		});

		setQuillInstance(quill);

		// Встановлення дефолтного тексту
		if (defaultText) {
			try {
				const parsedDelta = JSON.parse(defaultText);
				quill.setContents(parsedDelta);
			} catch (error) {
				// console.error('Error parsing defaultText as Delta:', error);
				quill.setText(defaultText);
			}
		}

		// Застосування форматів з defaultEntities
		if (defaultEntities && defaultEntities.length > 0) {
			defaultEntities.forEach((entity) => {
				const { type, offset, length, url } = entity;
				if (['bold', 'italic', 'underline', 'text_url'].includes(type)) {
					quill.formatText(
						offset,
						length,
						type === 'text_url' ? 'link' : type,
						type === 'text_url' ? url : true,
					);
				} else if (type === 'ordered' || type === 'bullet') {
					quill.formatLine(offset, length, 'list', type);
				}
			});
		}

		quill.on('text-change', () => {
			const delta = quill.getContents();
			const text = quill.getText().trim();
			const MAX_CHAR_LIMIT = 700;

			if (text.length > MAX_CHAR_LIMIT) {
				quill.deleteText(MAX_CHAR_LIMIT, text.length);
				alert(`Maximum character limit is ${MAX_CHAR_LIMIT}`);
			} else {
				setDescription(text);
				updateEntities(delta);
			}
		});

		const updateEntities = (delta) => {
			const entityList = [];
			let offset = 0;

			delta.ops.forEach((op) => {
				const insertLength = typeof op.insert === 'string' ? op.insert.length : 1;

				if (op.attributes) {
					Object.keys(op.attributes).forEach((attr) => {
						if (['bold', 'italic', 'underline', 'link'].includes(attr)) {
							entityList.push({
								type: attr === 'link' ? 'text_url' : attr,
								offset: offset,
								length: insertLength,
								url: attr === 'link' ? op.attributes.link : '',
							});
						} else if (attr === 'list') {
							entityList.push({
								type: op.attributes.list,
								offset: offset,
								length: insertLength,
							});
						}
					});
				}

				offset += insertLength;
			});

			setEntities(entityList);
		};
	}, [defaultText, defaultEntities, setDescription, setEntities, quillInstance]);

	return (
		<div>
			<div ref={quillRef} style={{ height: '300px', width: '100%' }} />
		</div>
	);
};
