import { PayloadAction } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	addAdvertiseRequest,
	addAdvertiseSuccess,
	addBotPromoRequest,
	aiChatBotInitState,
	botAllPromoCodeListRequest,
	botAllPromoCodeListSuccess,
	botPromoCodeListRequest,
	botPromoCodeListSuccess,
	botPromoCodeRequest,
	botPromoCodeRevokeRequest,
	botPromoCodeRevokeSuccess,
	botPromoCodeSuccess,
	createBotRequest,
	deleteAdvertiseImageRequest,
	deleteAdvertiseImageSuccess,
	deleteAdvertiseRequest,
	deleteAdvertiseSuccess,
	deleteBotRequest,
	deleteBotSuccess,
	editAdvertiseRequest,
	editAdvertiseSuccess,
	editBotRequest,
	editBotStructureInfoRequest,
	editBotStructureInfoSuccess,
	editBotSuccess,
	editBotTurnOfAndOnRequest,
	editButtonsRequest,
	editButtonsSuccess,
	editCommandRequest,
	editFrequencyRequest,
	editFrequencySuccess,
	editPricingRequest,
	editTemplatePhotoRequest,
	editTemplatePhotoSuccess,
	editTemplateTextRequest,
	editTemplateTextSuccess,
	getAdvertiseListRequest,
	getAdvertiseListSuccess,
	getAiChatBotsForCreateRequest,
	getAiChatBotsForCreateSuccess,
	getAiChatBotsRequest,
	getAiChatBotsSuccess,
	getBotDataRequest,
	getBotDataSuccess,
	getBotInfoRequest,
	getBotInfoSuccess,
	getBotsStatisticsRequest,
	getBotsStatisticsSuccess,
	getBotStructureInfoRequest,
	getBotStructureInfoSuccess,
	getBotСategoryRequest,
	getBotСategorySuccess,
	getButtonsRequest,
	getButtonsSuccess,
	getCommandsInfoRequest,
	getCommandsInfoSuccess,
	getCommandsRequest,
	getCommandsSuccess,
	getFrequencyRequest,
	getFrequencySuccess,
	getListLlmRequest,
	getListLlmSuccess,
	getPartnersRequest,
	getPartnersSuccess,
	getPricingRequest,
	getPricingSuccess,
	getRecommendedRequest,
	getRecommendedSuccess,
	getTemplatePhotoRequest,
	getTemplateTextRequest,
	getTemplateTextSuccess,
	getTotalStatisticsRequest,
	getTotalStatisticsSuccess,
	listLogoRequest,
	listLogoSuccess,
	removeLogoRequest,
	removeLogoSuccess,
	timeZoneListRequest,
	timeZoneListSuccess,
	uploadAdvertiseImageRequest,
	uploadAdvertiseImageSuccess,
	uploadBackgroundRequest,
	uploadBackgroundSuccess,
	uploadLogoRequest,
	uploadLogoSuccess,
} from './reducer';
import {
	IAddBotDataRequest,
	IAiChatBotsREquest,
	IAiChatBotsResponse,
	IAiModelList,
	IBot,
	IBotInfo,
	IBotInfoResponse,
	IBotPromoCode,
	IBotPromoCodeList,
	IBotPromoCodeListResonse,
	IBotTurnOfAndOn,
	IButtonBot,
	ICategory,
	ICommandAllData,
	ICreateBotRequest,
	IEditBot,
	IFrequenc,
	IListLogo,
	IPartners,
	IPricingRequest,
	IPricingResponse,
	IRecommendedReqvest,
	IRecommendedResponse,
	IRemoveRequest,
	IRequest,
	IRequestCodeRevoke,
	IRequestTemplate,
	IResponseAdvertiseList,
	IResponseTemplateText,
	IStatisticsBot,
	IStatisticsBotRequest,
	IStructureRequest,
	IStructureRsponse,
	ITimeZoneList,
	ITotalStatistics,
	ITotalStatisticsResponse,
	IUpload,
} from './types';

import { notificationContainer } from 'untils/notificationContainer';
import { ELinksName } from 'services/constants/list-links-sidebar';
import { EAiChatBotTabs } from 'services/constants/lists-table';

function* getAiChatBotsWorker({ payload }: PayloadAction<IAiChatBotsREquest>) {
	try {
		const response: { data: IAiChatBotsResponse } = yield call(
			api.aiChatBots.getAiChatBots,
			payload,
		);
		yield put(getAiChatBotsSuccess(response.data));
		yield put(getAiChatBotsForCreateSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getAiChatBotsForCreateWorker({ payload }: PayloadAction<IAiChatBotsREquest>) {
	try {
		const response: { data: IAiChatBotsResponse } = yield call(
			api.aiChatBots.getAiChatBots,
			payload,
		);
		yield put(getAiChatBotsForCreateSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotsStatisticsWorker({ payload }: PayloadAction<IStatisticsBotRequest>) {
	try {
		const response: { data: IStatisticsBot } = yield call(
			api.aiChatBots.getBotsStatistics,
			payload,
		);
		yield put(getBotsStatisticsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getTotalStatisticsWorker({ payload }: PayloadAction<ITotalStatistics>) {
	try {
		const response: { data: ITotalStatisticsResponse } = yield call(
			api.aiChatBots.getTotalStatistics,
			payload,
		);
		yield put(getTotalStatisticsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getPricingWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IPricingResponse } = yield call(api.aiChatBots.getPricing, payload);
		yield put(getPricingSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editPricingWorker({ payload }: PayloadAction<IPricingRequest>) {
	const { prevState, initial, request } = payload;

	try {
		yield call(api.aiChatBots.editPricing, request);
		notificationContainer('', 'success');
	} catch (error) {
		prevState(initial);
		notificationContainer('', 'error');

		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotInfoWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IBotInfo } = yield call(api.aiChatBots.getBotInfo, payload);
		yield put(getBotInfoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getCommondWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: ICommandAllData } = yield call(api.aiChatBots.getBotInfo, payload);
		yield put(getCommandsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editCommandWorker({ payload }: PayloadAction<ICommandAllData>) {
	try {
		yield call(api.aiChatBots.editCommand, payload);
		notificationContainer('', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotDataWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IBot } = yield call(api.aiChatBots.getBotData, payload);
		yield put(getBotDataSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotСategoryWorker() {
	try {
		const response: { data: { categories: Array<ICategory> } } = yield call(
			api.aiChatBots.getBotСategory,
		);
		yield put(getBotСategorySuccess(response.data.categories));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getButtonsWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IButtonBot } = yield call(api.aiChatBots.getButtons, payload);
		yield put(getButtonsSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getCommandsInfoWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: ICommandAllData } = yield call(api.aiChatBots.getCommandInfo, payload);
		yield put(getCommandsInfoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editBotWorker({ payload }: PayloadAction<IEditBot>) {
	try {
		yield call(api.aiChatBots.editBot, payload);
		yield put(editBotSuccess(payload));
		yield notificationContainer(' ', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editButtonWorker({ payload }: PayloadAction<IButtonBot>) {
	try {
		yield call(api.aiChatBots.editButtons, payload);
		yield put(editButtonsSuccess(payload));
		yield notificationContainer(' ', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* createBotWorker({ payload }: PayloadAction<ICreateBotRequest>) {
	try {
		yield call(api.aiChatBots.createBot, payload.data);
		notificationContainer('New bot created', 'success');
		payload.history(ELinksName.AI_CHAT_BOT);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
		notificationContainer('The bot was not created', 'error');
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getRecommendedWorker({ payload }: PayloadAction<IRecommendedReqvest>) {
	try {
		const response: { data: IRecommendedResponse } = yield call(
			api.aiChatBots.getRecommended,
			payload,
		);
		yield put(getRecommendedSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* uploadLogoWorker({ payload }: PayloadAction<IUpload>) {
	try {
		yield call(api.aiChatBots.uploadLogo, payload);
		yield put(uploadLogoSuccess());
		notificationContainer('Logo successfully added', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* uploadBackgroundWorker({ payload }: PayloadAction<IUpload>) {
	try {
		yield call(api.aiChatBots.uploadBackground, payload);
		yield put(uploadBackgroundSuccess());
		notificationContainer('Background successfully added', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editBotTurnOfAndOnWorker({ payload }: PayloadAction<IBotTurnOfAndOn>) {
	const { data, toggle } = payload;
	try {
		yield call(api.aiChatBots.editBotTurnOfAndOn, data);
	} catch (error) {
		toggle(false);
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* addBotPromoWorker({ payload }: PayloadAction<IAddBotDataRequest>) {
	try {
		const response: { message: string; status: string } = yield call(
			api.aiChatBots.addBotPromo,
			payload.data,
		);
		payload.closePopup(false);
		payload.resetInput();
		payload.param === EAiChatBotTabs.LIST_CODES && window.location.reload();
		payload.navigation(`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.LIST_CODES}`);
		notificationContainer(response.message, response.status);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* botPromoCodeWorker({ payload }: PayloadAction<IBotPromoCode>) {
	try {
		const response: { data: IBotInfoResponse } = yield call(api.aiChatBots.botPromoCode, payload);
		yield put(botPromoCodeSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* botPromoCodeRevokeWorker({ payload }: PayloadAction<IRequestCodeRevoke>) {
	const { onClose, body, navigation } = payload;

	try {
		yield call(api.aiChatBots.botPromoCodeRevoke, body);
		yield put(botPromoCodeRevokeSuccess(body));
		yield navigation?.(`${ELinksName.AI_CHAT_BOT}/${EAiChatBotTabs.LIST_CODES}`);
		yield onClose?.();
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* botPromoCodeListWorker({ payload }: PayloadAction<IBotPromoCodeList>) {
	try {
		const response: { data: IBotPromoCodeListResonse } = yield call(
			api.aiChatBots.botPromoCodeList,
			payload,
		);

		yield put(botPromoCodeListSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getAllBotPromoCodeListWorker({ payload }: PayloadAction<IBotPromoCodeList>) {
	try {
		const response: { data: IBotPromoCodeListResonse } = yield call(
			api.aiChatBots.botPromoCodeList,
			payload,
		);

		yield put(botAllPromoCodeListSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* logoListWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: Array<IListLogo> } = yield call(api.aiChatBots.getLogoList, payload);
		yield put(listLogoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* deleteLogoWorker({ payload }: PayloadAction<IRemoveRequest>) {
	try {
		yield call(api.aiChatBots.removeLogo, payload);
		yield put(removeLogoSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getTemplateTextWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IResponseTemplateText } = yield call(
			api.aiChatBots.getTemplateText,
			payload,
		);
		yield put(getTemplateTextSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editTemplateTextWorker({ payload }: PayloadAction<IRequestTemplate>) {
	try {
		const response: { data: Array<IListLogo> } = yield call(
			api.aiChatBots.editTemplateText,
			payload.data,
		);
		notificationContainer('', 'success');
		yield put(editTemplateTextSuccess(payload.data));
		payload.close?.('');
		payload.navigate?.(-1);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editTemplatePhotoWorker({ payload }: PayloadAction<IRequestTemplate>) {
	try {
		const response: { data: Array<IListLogo> } = yield call(
			api.aiChatBots.editTemplatePhoto,
			payload.data,
		);
		notificationContainer('', 'success');
		yield put(editTemplatePhotoSuccess(payload.data));
		payload.close?.('');
		payload.navigate?.(-1);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getTemplatePhotoWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IResponseTemplateText } = yield call(
			api.aiChatBots.getTemplatePhoto,
			payload,
		);
		yield put(editTemplatePhotoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getBotStructureInfoWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IStructureRsponse } = yield call(
			api.aiChatBots.getBotStructureInfo,
			payload,
		);
		yield put(getBotStructureInfoSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editBotStructureInfoWorker({ payload }: PayloadAction<IStructureRequest>) {
	try {
		yield call(api.aiChatBots.editBotStructureInfo, payload.request);
		notificationContainer('', 'success');
		yield put(editBotStructureInfoSuccess(payload.request));
		// payload.onReset();
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* deleteBotWorker({ payload }: PayloadAction<string>) {
	try {
		yield call(api.aiChatBots.deleteBot, payload);
		yield put(deleteBotSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getPartnersWorker() {
	try {
		const response: { data: IPartners } = yield call(api.aiChatBots.getPartners);
		yield put(getPartnersSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getAdvertiseListWorker({ payload }: PayloadAction<IRequest>) {
	try {
		const response: { data: IResponseAdvertiseList } = yield call(
			api.aiChatBots.getAdvertiseList,
			payload,
		);
		yield put(getAdvertiseListSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* addAdvertiseWorker({
	payload,
}: PayloadAction<{ dispatch: any; onClose: () => void; bot_id: string; formData: any }>) {
	try {
		const response: { data: IResponseAdvertiseList } = yield call(
			api.aiChatBots.addAdvertiseList,
			payload,
		);
		yield put(addAdvertiseSuccess(response.data));
		notificationContainer('', 'success');
		payload.onClose();
		payload.dispatch(
			getAdvertiseListRequest({
				bot_id: Number(payload.bot_id),
				current_page: 1,
				per_page: 15,
			}),
		);
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getTimeZoneListWorker() {
	try {
		const response: { data: { timezones: Array<ITimeZoneList> } } = yield call(
			api.aiChatBots.getTimeZoneList,
		);
		yield put(timeZoneListSuccess(response.data.timezones));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editAdvertiseWorker({ payload }: PayloadAction<any>) {
	try {
		const response: { data: IResponseAdvertiseList } = yield call(
			api.aiChatBots.editAdvertiseList,
			payload,
		);
		yield put(editAdvertiseSuccess(response.data));
		notificationContainer('', 'success');
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* deleteAdvertiseWorker({
	payload,
}: PayloadAction<{ onClose: () => void; data: { bot_id: string; id: string } }>) {
	try {
		yield call(api.aiChatBots.deleteAdvertise, payload.data);
		yield put(deleteAdvertiseSuccess(payload.data));
		payload.onClose();
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* deleteAdvertiseImageWorker({
	payload,
}: PayloadAction<{ bot_id: string; id: string; hash: string }>) {
	try {
		const response: { data: IResponseAdvertiseList } = yield call(
			api.aiChatBots.deleteAdvertiseImage,
			payload,
		);
		yield put(deleteAdvertiseImageSuccess(payload));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* uploadAdvertiseImageWorker({
	payload,
}: PayloadAction<{ bot_id: string; id: string; body: any }>) {
	try {
		const response: { data: IResponseAdvertiseList } = yield call(
			api.aiChatBots.uploadAdvertiseImage,
			payload,
		);
		yield put(uploadAdvertiseImageSuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getFrequencyWorker({ payload }: PayloadAction<string>) {
	try {
		const response: { data: IFrequenc } = yield call(api.aiChatBots.getFrequency, payload);
		yield put(getFrequencySuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* editFrequencyWorker({ payload }: PayloadAction<IFrequenc>) {
	try {
		const response: { data: IFrequenc } = yield call(api.aiChatBots.editFrequency, payload);
		yield put(editFrequencySuccess(response.data));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

function* getListLlmWorker() {
	try {
		const response: { data: { models: Array<IAiModelList> } } = yield call(
			api.aiChatBots.aiModelList,
		);
		yield put(getListLlmSuccess(response.data.models));
	} catch (error) {
		if (isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(aiChatBotInitState());
	}
}

export function* aiChatBotsSaga() {
	yield takeEvery(getAiChatBotsRequest.type, getAiChatBotsWorker);
	yield takeEvery(getBotsStatisticsRequest.type, getBotsStatisticsWorker);
	yield takeEvery(getTotalStatisticsRequest.type, getTotalStatisticsWorker);
	yield takeEvery(getPricingRequest.type, getPricingWorker);
	yield takeEvery(editPricingRequest.type, editPricingWorker);
	yield takeEvery(getBotInfoRequest.type, getBotInfoWorker);
	yield takeEvery(getCommandsRequest.type, getCommondWorker);
	yield takeEvery(editCommandRequest.type, editCommandWorker);
	yield takeEvery(getBotDataRequest.type, getBotDataWorker);
	yield takeEvery(getBotСategoryRequest.type, getBotСategoryWorker);
	yield takeEvery(getButtonsRequest.type, getButtonsWorker);
	yield takeEvery(getCommandsInfoRequest.type, getCommandsInfoWorker);
	yield takeEvery(editBotRequest.type, editBotWorker);
	yield takeEvery(editButtonsRequest.type, editButtonWorker);
	yield takeEvery(createBotRequest.type, createBotWorker);
	yield takeEvery(getRecommendedRequest.type, getRecommendedWorker);
	yield takeEvery(uploadLogoRequest.type, uploadLogoWorker);
	yield takeEvery(uploadBackgroundRequest.type, uploadBackgroundWorker);
	yield takeEvery(editBotTurnOfAndOnRequest.type, editBotTurnOfAndOnWorker);
	yield takeEvery(addBotPromoRequest.type, addBotPromoWorker);
	yield takeLatest(botPromoCodeRequest.type, botPromoCodeWorker);
	yield takeEvery(botPromoCodeRevokeRequest.type, botPromoCodeRevokeWorker);
	yield takeEvery(botPromoCodeListRequest.type, botPromoCodeListWorker);
	yield takeEvery(botAllPromoCodeListRequest.type, getAllBotPromoCodeListWorker);
	yield takeEvery(listLogoRequest.type, logoListWorker);
	yield takeEvery(removeLogoRequest.type, deleteLogoWorker);
	yield takeEvery(getTemplateTextRequest.type, getTemplateTextWorker);
	yield takeEvery(editTemplateTextRequest.type, editTemplateTextWorker);
	yield takeEvery(editTemplatePhotoRequest.type, editTemplatePhotoWorker);
	yield takeEvery(getTemplatePhotoRequest.type, getTemplatePhotoWorker);
	yield takeEvery(getBotStructureInfoRequest.type, getBotStructureInfoWorker);
	yield takeEvery(editBotStructureInfoRequest.type, editBotStructureInfoWorker);
	yield takeEvery(deleteBotRequest.type, deleteBotWorker);
	yield takeEvery(getAiChatBotsForCreateRequest.type, getAiChatBotsForCreateWorker);
	yield takeEvery(getPartnersRequest.type, getPartnersWorker);
	yield takeEvery(getAdvertiseListRequest.type, getAdvertiseListWorker);
	yield takeEvery(addAdvertiseRequest.type, addAdvertiseWorker);
	yield takeEvery(timeZoneListRequest.type, getTimeZoneListWorker);
	yield takeEvery(editAdvertiseRequest.type, editAdvertiseWorker);
	yield takeEvery(deleteAdvertiseRequest.type, deleteAdvertiseWorker);
	yield takeEvery(deleteAdvertiseImageRequest.type, deleteAdvertiseImageWorker);
	yield takeEvery(uploadAdvertiseImageRequest.type, uploadAdvertiseImageWorker);
	yield takeEvery(getFrequencyRequest.type, getFrequencyWorker);
	yield takeEvery(editFrequencyRequest.type, editFrequencyWorker);
	yield takeEvery(getListLlmRequest.type, getListLlmWorker);
}
