import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../AddNewTemplate/AddNewTemplate.scss';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';
import moment from 'moment';
import { toZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';
import { getTimeZoneListSelector } from 'redux/reducers/ai-chat-bot/selectors';
import { ITimeZoneList } from 'redux/reducers/ai-chat-bot/types';
import { ArrowDownSvg } from 'assets/svg-icon';
import { useSelect } from 'hooks/useSelect';

export interface IAddDateAndTime {
	time_from: string;
	time_to: string;
	time_zone: string;
}

interface IProps {
	dateAndTimeStart?: string;
	dateAndTimeEnd?: string;
	gmto?: string;
	editAdvertise?: any;
	addDateAndTime?: (value: IAddDateAndTime) => void;
}

const TimeZoneFilter: React.FC<IProps> = ({
	editAdvertise,
	addDateAndTime,
	dateAndTimeStart,
	dateAndTimeEnd,
	gmto,
}) => {
	const { open, toggleOpen, triggerRef, dropRef, setOpen } = useSelect();
	const [startDate, setStartDate] = useState<Date | null>(new Date(dateAndTimeStart || new Date()));
	const [startTime, setStartTime] = useState<Date | null>(new Date(dateAndTimeStart || new Date()));
	const [endDate, setEndDate] = useState<Date | null>(new Date(dateAndTimeEnd || new Date()));
	const [endTime, setEndTime] = useState<Date | null>(new Date(dateAndTimeEnd || new Date()));
	const [timezone, setTimezone] = useState<string>(gmto ? gmto : 'Pacific/Midway');
	const getTimeZoneList = useSelector(getTimeZoneListSelector);
	const [timeZoneList, setTimeZoneList] = useState(getTimeZoneList);
	// const [openDrop, setOpenDrop] = useState(false);

	const handleTimezoneChange = (event: React.MouseEvent) => {
		const { id } = event.target as HTMLLIElement;
		const now = new Date(); // Поточний час у UTC
		const zonedTime = toZonedTime(now, id);
		setTimezone(id);
		setOpen(false);
		setStartTime(zonedTime);
		setEndTime(zonedTime);
		setStartDate(zonedTime);
		setEndDate(zonedTime);
		console.log(zonedTime);
	};

	const onSearch = (event: React.ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;
		setTimeZoneList(
			getTimeZoneList?.filter(
				(zone: ITimeZoneList) =>
					zone.zone.toLowerCase().includes(value.toLowerCase()) ||
					zone.gmt.toLowerCase().includes(value.toLowerCase()),
			) || [],
		);
		value && setOpen(true);
		setTimezone(value);
	};

	useEffect(() => {
		console.log(timezone);
		editAdvertise?.({
			time_from: `${moment(startDate).format('YYYY-MM-DD')} ${moment(startTime).format(
				'HH:mm:ss',
			)}`,
			time_to: `${moment(endDate).format('YYYY-MM-DD')} ${moment(endTime).format('HH:mm:ss')}`,
			time_zone: timezone,
		});

		addDateAndTime?.({
			time_from: `${moment(startDate).format('YYYY-MM-DD')} ${moment(startTime).format(
				'HH:mm:ss',
			)}`,
			time_to: `${moment(endDate).format('YYYY-MM-DD')} ${moment(endTime).format('HH:mm:ss')}`,
			time_zone: timezone,
		});
	}, [startDate, startTime, endDate, endTime, timezone]);

	useEffect(() => {}, [getTimeZoneList]);

	return (
		<div className="timezone-filter">
			<div className="date-picker-wrap">
				<DatePicker
					selected={startDate}
					onChange={(date) => {
						if (date && moment(date).isAfter(endDate)) {
							setEndDate(date); // Якщо нова початкова дата більша за кінцеву, синхронізуємо кінцеву дату з початковою
						}
						setStartDate(date);
					}}
					placeholderText="Start date"
					dateFormat="dd.MM.yyyy"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					minDate={new Date()} // Мінімально допустима дата
				/>
				<FaCalendarAlt className="icon" />
			</div>
			<div className="date-picker-wrap">
				<DatePicker
					selected={startTime}
					onChange={(time) => setStartTime(time)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={30}
					timeCaption="Time"
					dateFormat="HH:mm:ss"
					timeFormat="HH:mm" // 24-годинний формат для списку часу
					placeholderText="Start time"
				/>
				<FaClock className="icon" />
			</div>
			<div className="date-picker-wrap end-date">
				<DatePicker
					selected={endDate}
					onChange={(date) => {
						if (date && moment(date).isBefore(startDate)) {
							alert('End date cannot be earlier than the start date.');
							return; // Якщо дата завершення менша за початкову, не оновлюємо стан
						}
						setEndDate(date);
					}}
					placeholderText="End date"
					dateFormat="dd.MM.yyyy"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					minDate={startDate} // Мінімально допустима дата
				/>
				<FaCalendarAlt className="icon" />
			</div>
			<div className="date-picker-wrap end-time">
				<DatePicker
					selected={endTime}
					onChange={(time) => setEndTime(time)}
					showTimeSelect
					showTimeSelectOnly
					timeIntervals={30}
					timeCaption="Time"
					dateFormat="HH:mm:ss"
					timeFormat="HH:mm" // 24-годинний формат для списку часу
					placeholderText="End time"
				/>
				<FaClock className="icon" />
			</div>
			<div ref={dropRef} className="drop-search">
				<input
					onChange={onSearch}
					type="text"
					value={timezone}
					ref={triggerRef}
					onClick={toggleOpen}
				/>
				<span className="pointer-events">
					<ArrowDownSvg />
				</span>
				<div className={open ? 'open-drop' : ''}>
					<ul>
						{timeZoneList?.map((zone) => (
							<li id={zone.zone} key={zone.id} value={zone.zone} onClick={handleTimezoneChange}>
								{zone.zone + ' ' + zone.gmt}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default TimeZoneFilter;
