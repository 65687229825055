import { FC } from 'react';
import { ListCodeHeader } from './ListCodeHeader/ListCodeHeader';
import { IDataFiltter } from 'pages/Dashboard/Channels';
import { ListCodeBody } from './ListCodeBody/ListCodeBody';
import { DownloadSvg } from 'assets/svg-icon';
import { IBotPromoCodeListResonse } from 'redux/reducers/ai-chat-bot/types';
import { handleDownload } from 'untils/xlsx';
interface IProps {
	dataFiltter: IDataFiltter;
	setDataFiltter: (value: IDataFiltter) => void;
	botPromoCodeList: null | IBotPromoCodeListResonse;
	allBotsListCode: null | IBotPromoCodeListResonse;
}

export const ListCodes: FC<IProps> = ({
	setDataFiltter,
	dataFiltter,
	botPromoCodeList,
	allBotsListCode,
}) => {
	return (
		<div className="table-wrapper">
			<div className="wrap-export-btn">
				<button
					onClick={() => handleDownload(allBotsListCode?.list)}
					style={{
						display: 'flex',
						gap: '5px',
						background: '#fff',
						color: '#0A49A5',
						border: '1px solid #CFCFD2',
					}}
					className="add-btn"
					type="button"
				>
					<DownloadSvg />
					Export Excel
				</button>
			</div>
			<div className="table table--list-codes">
				<ListCodeHeader setDataFiltter={setDataFiltter} dataFiltter={dataFiltter} />
				<div className="table-body">
					{botPromoCodeList?.list.map((code, index) => (
						<ListCodeBody key={index} code={code} />
					))}
				</div>
			</div>
		</div>
	);
};
