import classNames from 'classnames';
import ErrorMsg from 'components/ErrorMsg';
import { ChangeEvent, FC } from 'react';
import { ICategory } from 'redux/reducers/ai-chat-bot/types';
import { SelectList } from 'ui/SelectList/SelectList';
// import { SelectList } from 'ui/SelectList/SelectList';

interface IProps {
	categoryList: Array<ICategory> | null;
	onSelect: (value: string) => void;
	onInputs: (event: ChangeEvent) => void;
	name: string;
	username: string;
	category: string;
}

export const Main: FC<IProps> = ({
	category,
	name,
	username,
	categoryList,
	onSelect,
	onInputs,
}) => {
	return (
		<div className="edit-main">
			<div className="edit-main__body creat-bot-title">
				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Name</div>
					<input
						onChange={onInputs}
						name="name"
						placeholder="Enter name"
						value={name}
						type="text"
						className="edit-main__input"
					/>
					<span className={classNames('edit-main__count', { red: 30 - name.length === 0 })}>
						{30 - name.length}
					</span>

					{!name ? <ErrorMsg msg={'This field is required'} /> : null}
				</div>
				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Username</div>
					<input
						onChange={onInputs}
						value={username}
						name="username"
						placeholder="Enter username"
						type="text"
						className="edit-main__input"
					/>
					<span className={classNames('edit-main__count', { red: 30 - username.length === 0 })}>
						{30 - username.length}
					</span>
					{!username ? <ErrorMsg msg={'This field is required'} /> : null}
				</div>
				{/* <div className="edit-main__input-wrp">
					<div className="edit-main__label">Lim</div>
					<SelectList
						defaultSelect={category}
						onSelect={onSelect}
						placeholder="Enter lim"
						data={categoryList?.map((item) => item.category) || ['Empty']}
					/>
					{!category ? <ErrorMsg msg={'This field is required'} /> : null}
				</div> */}
				{/* <div className="edit-main__input-wrp">
					<div className="edit-main__label">Description</div>
					<input
						name="description"
						placeholder="Enter description"
						type="text"
						className="edit-main__input"
					/>
				</div>
				<div className="edit-main__input-wrp">
					<div className="edit-main__label">Category</div>
					<SelectList
						onSelect={onSelect}
						placeholder="Enter category"
						data={categoryList?.map((item) => item.category) || ['Empty']}
					/>
				</div> */}
			</div>
		</div>
	);
};
