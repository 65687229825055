import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import {
	ArrowDownChatSvg,
	ArrowUpChatSvg,
	BoltSvg,
	ClipSvg,
	CompletedSvg,
	InProgresSvg,
	NewSvg,
	SmileSvg,
} from 'assets/svg-icon';
import classNames from 'classnames';
import { SelectList } from 'ui/SelectList/SelectList';
import { Chat } from 'components/Chat/Chat';
import { useDispatch, useSelector } from 'react-redux';
import {
	changeStatusRequest,
	changeTypeRequest,
	getMassageRequest,
	resetMassage,
	sendLetterRequest,
	setTicket,
	uploadFileRequest,
} from 'redux/reducers/support/reducer';
import {
	getChatList,
	getMasageSelector,
	getTickets,
	getTypesSelector,
	getUploadLoader,
} from 'redux/reducers/support/selectors';
import moment from 'moment';
import { getUserDetails } from 'redux/reducers/users/selectors';
import { FileUploader } from 'react-drag-drop-files';
import { fileTypes } from 'CONST/file-types';
import { Emoji } from 'ui/InputFile/InputFile';
import { createAvataar } from 'untils/createAvatar';
import { scrollBottom } from 'untils/scrollBottom';
import { Avatar } from 'ui/Avatar/Avatar';
import { Loader } from 'components/Loader';
import { getTabNameSupport } from 'redux/reducers/app/selectors';
import { SmallLoader } from 'ui/SmallLoader';

const status = [
	{ name: 'New', svg: NewSvg },
	{ name: 'In progress', svg: InProgresSvg },
	{ name: 'Completed', svg: CompletedSvg },
	// { name: 'Custom 1', svg: CustomSvg },
	// { name: 'Custom 2', svg: CustomTwoSvg },
];

const Suport: FC = () => {
	const dispatch = useDispatch();
	const massages = useSelector(getChatList);
	const support_id = useSelector(getMasageSelector)?.messages[0].support_id;
	const tickets = useSelector(getTickets);
	const userData = useSelector(getUserDetails);
	const [check, setCheck] = useState<number>(0);
	const [chats, setChats] = useState<Array<{
		admin: boolean;
		message: string;
		hash?: string;
		type?: string;
		mime_type?: string;
		file_name?: string;
	}> | null>(massages);
	const [chat, setChat] = useState<string>('');
	const [file, setFile] = useState(null);
	const [openEmoji, setOpenEmoji] = useState<boolean>(false);
	const scrollRef = useRef<HTMLDivElement>(null);
	const types = useSelector(getTypesSelector);
	const btnTabName = useSelector(getTabNameSupport);
	const loader = useSelector(getUploadLoader);

	const handleChange = (file: File) => {
		const MAX_FILE_SIZE = 3 * 1024 * 1024; // 3 MB

		if (file.size > MAX_FILE_SIZE) {
			alert('The file size exceeds the 3MB limit. Please choose a smaller file.');
			return;
		}

		const reader = new FileReader();
		const formData = new FormData();
		//@ts-ignore
		formData.append('file', file);

		dispatch(
			uploadFileRequest({
				user_id: tickets?.data[0].user_id as number,
				channel_id: support_id as number,
				body: {
					file: formData,
				},
			}),
		);
		//@ts-ignore
		setFile(reader.readAsDataURL(file));
	};

	const handleChat = (num: number) => {
		setCheck(num === check ? 0 : num);
		num !== check && dispatch(resetMassage());
		dispatch(
			getMassageRequest({
				current_page: 1,
				per_page: 100,
				id: num,
			}),
		);
	};

	const addEmoji = (value: any) => {
		setChat((prev) => prev + ' ' + value.native);
	};

	const onAddChats = (event: KeyboardEvent) => {
		const { key } = event;

		if (key === 'Enter' && chat.trim()) {
			dispatch(
				sendLetterRequest({
					key_channel: `public:${check}`,
					body: chat,
					channel_id: check as number,
					offset: 0,
					epoch: '',
					reply: null,
					is_update: false,
					topic: '',
					from_app: false,
					user_id: tickets?.data[0].user_id as number,
				}),
			);
			chats && setChats([...chats, { admin: true, message: chat }]);
			dispatch(setTicket(chat));
			setChat('');
		}
	};

	const handleInputChat = (event: ChangeEvent) => {
		const { value } = event.target as HTMLInputElement;

		setChat(value);
	};

	const onChangeStatus = (value: string, id?: number | string) => {
		let newStatus: number | undefined;
		switch (value) {
			case 'new':
				newStatus = 0;
				break;

			case 'in_progress':
				newStatus = 1;
				break;

			case 'completed':
				newStatus = 2;
				break;
		}
		dispatch(
			changeStatusRequest({
				data: {
					id: id as number,
					type: newStatus as number,
				},
				btnTabName,
			}),
		);
	};

	const onChangeType = (value: string, id?: number | string) => {
		dispatch(
			changeTypeRequest({
				id: id as number,
				type: types?.find((type) => type.Title.toLowerCase() === value.replaceAll('_', ' '))
					?.Id as number,
			}),
		);
	};

	const onStatus = (value: number) => {
		switch (value) {
			case 0:
				return 'New';

			case 1:
				return 'In Progress';

			case 2:
				return 'Completed';
		}
	};

	useEffect(() => {
		setChats(massages);

		// return () => {
		// 	dispatch(ticketsResponse(null));
		// };
	}, [massages]);

	useEffect(() => {
		if (scrollRef?.current) {
			scrollRef.current?.scrollBy({
				top: scrollBottom(scrollRef.current),
			});
		}
	}, [chat, scrollRef.current, massages, chats]);

	return (
		<div className="main-content chat">
			{tickets ? (
				tickets?.data?.map((el) => {
					return (
						<div
							style={{
								height: check === el.support_id ? '100%' : '',
								marginBottom: check === el.support_id ? '25px' : '',
							}}
							// className="height-max"
							key={el.support_id}
						>
							<div
								title={
									el?.admin_info
										? el?.admin_info?.first_name?.String + ' ' + el?.admin_info?.last_name?.String
										: ''
								}
								className={classNames('chat__box', {
									'content-activ': check === el.support_id,
								})}
							>
								<div className="users__ava-wrap">
									{userData?.data.photo_access_hash ? (
										<Avatar hash={String(userData.data.photo_access_hash)} />
									) : (
										<div className="avatar-custom">
											<span>
												{createAvataar(userData?.data.first_name + ' ' + userData?.data.last_name)}
											</span>
										</div>
									)}
									<div className="users__info">
										<span className="users__text-bolt">
											{userData?.data.first_name + ' ' + userData?.data.last_name}
										</span>
										<span className="users__text-regular">{el?.type_name?.String}</span>
									</div>
								</div>
								<div className="chat__arrow-wrap">
									<div className="users__info line">
										<span className={`users__status users__status--${el.status.Int64}`}>
											<BoltSvg />
											<span>{onStatus(el.status.Int64)}</span>
										</span>
										<span className="users__text-regular">
											{moment(el.created_at).format('MMM DD, YYYY')}
										</span>
									</div>
									<span onClick={() => handleChat(el.support_id)} className="pointer">
										{check === el.support_id ? <ArrowUpChatSvg /> : <ArrowDownChatSvg />}
									</span>
								</div>
							</div>
							{check === el.support_id && (
								<div
									className={classNames('chat__hidden', {
										chat__content: check === el.support_id,
									})}
								>
									<div className="chat__content-header">
										<div className="chat__select-left">
											<SelectList
												id={el.support_id}
												onSelect={onChangeType}
												data={types?.map((type) => type.Title) || []}
												placeholder={'Personal Details'}
											/>
										</div>
										<div className="chat__select-right">
											<SelectList
												id={el.support_id}
												onSelect={onChangeStatus}
												data={status}
												placeholder={'Status'}
											/>
										</div>
									</div>
									<div ref={scrollRef} className="chat__content-body">
										{(chats || massages)?.map((chat, index) => {
											return (
												<Chat
													key={index}
													hash={userData?.data.photo_access_hash}
													username={userData?.data.first_name + ' ' + userData?.data.last_name}
													chat={chat.message}
													admin={chat.admin}
													hashImage={chat.hash}
													type={chat.type}
													mime_type={chat.mime_type}
													file_name={chat.file_name}
												/>
											);
										})}
									</div>
									<div className="chat__content-footer">
										<div className="chat__input-text-wrap">
											<button
												onClick={() => setOpenEmoji(!openEmoji)}
												className="chat__input-svg-smile"
												type="button"
											>
												<SmileSvg />
												{openEmoji ? <Emoji addEmoji={addEmoji} /> : null}
											</button>
											<input
												onKeyUp={(event: KeyboardEvent) => onAddChats(event)}
												onChange={handleInputChat}
												value={chat}
												className="chat__input-text"
												type="text"
											/>

											<button className="chat__input-svg-clip" type="button">
												{loader ? (
													<SmallLoader />
												) : (
													<>
														<FileUploader
															multiple={false}
															handleChange={handleChange}
															name="file"
															types={fileTypes}
														/>
														<ClipSvg />
													</>
												)}
											</button>
										</div>
									</div>
								</div>
							)}
						</div>
					);
				})
			) : (
				<Loader />
			)}
		</div>
	);
};

export default Suport;
