import { FC, ReactNode } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { SmallLoader } from 'ui/SmallLoader';

const fileTypes = [
	'JPEG', // image
	'JPG', // image
	'PNG', // image
	'SVG', // image
	'MP4', // Video
];

interface IProps {
	handleChange: (value: any) => void;
	file?: any;
	title?: string;
	subtitle?: string;
	svg: ReactNode;
	photo?: string;
	name?: string;
	nameFile?: string;
	size?: number;
	loader?: boolean;
	multiple?: boolean;
}

export const DropFile: FC<IProps> = ({
	photo,
	handleChange,
	file,
	title,
	subtitle,
	svg,
	name,
	nameFile,
	size,
	loader,
	multiple,
}) => {
	return (
		<div className="background__wrapper-drop">
			<div className="background__title-drop-wrap">
				<div className="background__title-drop">{title || ''}</div>
				<div className="background__subtitle-drop">{subtitle || ''}</div>
			</div>
			<div className="background__inner-drop">
				<div className="background__img">
					{photo ? (
						<img className="" src={photo} alt="" />
					) : (
						<div className="background__img-svg">{svg}</div>
					)}
					<FileUploader
						multiple={multiple}
						classes="opacity"
						handleChange={handleChange}
						name={name || 'file'}
						types={fileTypes}
					/>
				</div>
				{!photo && <p className="background__title">Drop file here</p>}
				<p className="background__text-gray">
					{file || nameFile
						? `File name: ${file?.name || nameFile || ''}`
						: `Recommended Size: 2 375 x 1 812 (PNG, SVG, JPEG, MP4)`}
				</p>
				{size ? (
					<p style={{ marginTop: 0 }} className="background__text-gray">{`Size: ${size}MB`}</p>
				) : null}
				<button className="background__drop-dtn">
					<FileUploader
						multiple={multiple}
						classes="opacity"
						handleChange={handleChange}
						name="file"
						types={fileTypes}
					/>
					{loader ? (
						<span className="oval-wrap">
							<SmallLoader />
						</span>
					) : (
						<span className="background__btn-name">Browse file</span>
					)}
				</button>
			</div>
		</div>
	);
};
