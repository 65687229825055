import { ChangeEvent, FC, KeyboardEvent, useEffect, useState } from 'react';
import { SubTabs } from '../WithdrawalRequests/SubTabs/SubTabs';
import { BankSvg, BusdSvg, DolarSvg, EtnSvg, PaySvg, StripeSvg, UsdtSvg } from 'assets/svg-icon';
import Toggle from 'react-toggle';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMethodsRequest, setMethodsRequest } from 'redux/reducers/finance/finance-reducer';
import { IMethods } from 'redux/reducers/finance/finance-types';

interface IProps {
	methods: IMethods;
}

export const PaymentMethods: FC<IProps> = ({ methods }) => {
	const [toggleDepPayPal, setToggleDepPayPal] = useState<boolean>(
		methods.paypal_deposit_limit_check as boolean,
	);
	const [toggleDepStripe, setToggleDepStripe] = useState<boolean>(
		methods.stripe_deposit_limit_check as boolean,
	);
	const [toggleWithPayPal, setToggleWithPayPal] = useState<boolean>(
		methods.paypal_withdraw_limit_check as boolean,
	);
	const [toggleWithStripe, setToggleWithStripe] = useState<boolean>(
		methods.base_fiat_withdraw_limit_check as boolean,
	);
	const params = useParams();
	const dispatch = useDispatch();
	const [method, setMethod] = useState<IMethods>({
		base_fiat_deposit_limit_check: methods.base_fiat_deposit_limit_check,
		base_fiat_max_deposit_amount: methods.base_fiat_max_deposit_amount,
		base_fiat_max_withdraw_amount: methods.base_fiat_max_withdraw_amount,
		base_fiat_min_deposit_amount: methods.base_fiat_min_deposit_amount,
		base_fiat_min_withdraw_amount: methods.base_fiat_min_withdraw_amount,
		base_fiat_withdraw_limit_check: methods.base_fiat_withdraw_limit_check,
		paypal_deposit_limit_check: methods.paypal_deposit_limit_check,
		paypal_max_deposit_amount: methods.paypal_max_deposit_amount,
		paypal_max_withdraw_amount: methods.paypal_max_withdraw_amount,
		paypal_min_deposit_amount: methods.paypal_min_deposit_amount,
		paypal_min_withdraw_amount: methods.paypal_min_withdraw_amount,
		paypal_withdraw_limit_check: methods.paypal_withdraw_limit_check,
		stripe_deposit_limit_check: methods.stripe_deposit_limit_check,
		stripe_max_deposit_amount: methods.stripe_max_deposit_amount,
		stripe_max_withdraw_amount: methods.stripe_max_withdraw_amount,
		stripe_min_deposit_amount: methods.stripe_min_deposit_amount,
		stripe_min_withdraw_amount: methods.stripe_min_withdraw_amount,
		stripe_withdraw_limit_check: methods.stripe_withdraw_limit_check,
	});

	const defaultMethod = () => {
		methods && setMethod(methods);
	};

	const handleInput = (event: ChangeEvent) => {
		const { name, value } = event.target as HTMLInputElement;
		setMethod((prev) => ({ ...prev, [name]: +value.replaceAll(/\D/g, '') || ' ' }));
	};

	const onSubmitMethod = (event: KeyboardEvent) => {
		if (event.code === 'Enter') {
			dispatch(setMethodsRequest(method));
			setTimeout(() => {
				//@ts-ignore
				event.target.blur();
			}, 1000);
		}
	};

	const onSubmitToggle = (event: ChangeEvent, toggle: boolean) => {
		const { name } = event.target as HTMLInputElement;

		dispatch(setMethodsRequest({ ...method, [name]: toggle }));
		setMethod((prev) => ({ ...prev, [name]: toggle }));
	};

	// useEffect(() => {
	// 	dispatch(getMethodsRequest());
	// }, []);

	return (
		<>
			<SubTabs />
			<div className="payment-methods">
				<div className="payment-methods__wrapper">
					<div className="payment-methods__title">Deposits</div>
					<div className="payment-methods__box-wrapper">
						{/* <div className="payment-methods__row">
							<div className="payment-methods__box-left">
								<div className="payment-methods__svg">
									<DolarSvg />
								</div>
								<div className="payment-methods__name">USD Fiat</div>
							</div>
							<div className="payment-methods__box-right">
								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Minimum amount</span>
									<input type="text" className="payment-methods__percent" />
								</label>

								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Maximum amount</span>
									<input type="text" className="payment-methods__percent" />
								</label>
								<div className="user-data__toggle-wrapper left">
									<span className="user-data__toggle-text payment-methods__width">
										{toggle ? 'Turned ON' : 'Turned OFF'}
									</span>
									<Toggle defaultChecked={toggle} icons={false} onChange={handleTofuChange} />
								</div>
							</div>
						</div> */}
						<div className="payment-methods__row">
							<div className="payment-methods__box-left">
								<div className="payment-methods__svg">
									<PaySvg />
								</div>
								<div className="payment-methods__name">PayPal</div>
							</div>
							<div className="payment-methods__box-right">
								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Minimum amount</span>
									<input
										name="paypal_min_deposit_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={(event: KeyboardEvent) => {
											if (
												Number(method.paypal_max_deposit_amount) <=
													Number(method.paypal_min_deposit_amount) &&
												event.code === 'Enter'
											) {
												alert('The minimum amount cannot exceed the maximum amount.');
												return; // Якщо дата завершення менша за початкову, не оновлюємо стан
											}
											onSubmitMethod(event);
										}}
										value={method.paypal_min_deposit_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>

								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Maximum amount</span>
									<input
										name="paypal_max_deposit_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={(event: KeyboardEvent) => {
											if (
												Number(method.paypal_min_deposit_amount) >=
													Number(method.paypal_max_deposit_amount) &&
												event.code === 'Enter'
											) {
												alert('The maximum amount cannot exceed the minimum amount.');
												return; // Якщо дата завершення менша за початкову, не оновлюємо стан
											}
											onSubmitMethod(event);
										}}
										value={method.paypal_max_deposit_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>
								<div className="user-data__toggle-wrapper left">
									<span className="user-data__toggle-text payment-methods__width">
										{!toggleDepPayPal ? 'Turned ON' : 'Turned OFF'}
									</span>
									<Toggle
										name="paypal_deposit_limit_check"
										defaultChecked={!toggleDepPayPal}
										icons={false}
										onChange={(event: ChangeEvent) => {
											onSubmitToggle(event, !toggleDepPayPal);
											setToggleDepPayPal(!toggleDepPayPal);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="payment-methods__row">
							<div className="payment-methods__box-left">
								<div className="payment-methods__svg">
									<StripeSvg />
								</div>
								<div className="payment-methods__name">Stripe</div>
							</div>
							<div className="payment-methods__box-right">
								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Minimum amount</span>
									<input
										name="stripe_min_deposit_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={(event: KeyboardEvent) => {
											if (
												Number(method.stripe_max_deposit_amount) <=
													Number(method.stripe_min_deposit_amount) &&
												event.code === 'Enter'
											) {
												alert('The minimum amount cannot exceed the maximum amount.');
												return; // Якщо дата завершення менша за початкову, не оновлюємо стан
											}
											onSubmitMethod(event);
										}}
										value={method.stripe_min_deposit_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>

								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Maximum amount</span>
									<input
										name="stripe_max_deposit_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={(event: KeyboardEvent) => {
											if (
												Number(method.stripe_min_deposit_amount) >=
													Number(method.stripe_max_deposit_amount) &&
												event.code === 'Enter'
											) {
												alert('The minimum amount cannot exceed the maximum amount.');
												return; // Якщо дата завершення менша за початкову, не оновлюємо стан
											}
											onSubmitMethod(event);
										}}
										value={method.stripe_max_deposit_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>
								<div className="user-data__toggle-wrapper left">
									<span className="user-data__toggle-text payment-methods__width">
										{!toggleDepStripe ? 'Turned ON' : 'Turned OFF'}
									</span>
									<Toggle
										name="stripe_deposit_limit_check"
										defaultChecked={!toggleDepStripe}
										icons={false}
										onChange={(event: ChangeEvent) => {
											onSubmitToggle(event, !toggleDepStripe);
											setToggleDepStripe(!toggleDepStripe);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="payment-methods__wrapper">
					<div className="payment-methods__title">Withdrawals</div>
					<div className="payment-methods__box-wrapper">
						{/* <div className="payment-methods__row">
							<div className="payment-methods__box-left">
								<div className="payment-methods__svg">
									<DolarSvg />
								</div>
								<div className="payment-methods__name">USD Fiat</div>
							</div>
							<div className="payment-methods__box-right">
								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Minimum amount</span>
									<input type="text" className="payment-methods__percent" />
								</label>

								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Maximum amount</span>
									<input type="text" className="payment-methods__percent" />
								</label>
								<div className="user-data__toggle-wrapper left">
									<span className="user-data__toggle-text payment-methods__width">
										{toggle ? 'Turned ON' : 'Turned OFF'}
									</span>
									<Toggle defaultChecked={toggle} icons={false} onChange={handleTofuChange} />
								</div>
							</div>
						</div> */}
						{/* <div className="payment-methods__row">
							<div className="payment-methods__box-left">
								<div className="payment-methods__svg">
									<PaySvg />
								</div>
								<div className="payment-methods__name">PayPal</div>
							</div>
							<div className="payment-methods__box-right">
								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Minimum amount</span>
									<input
										name="paypal_min_withdraw_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={onSubmitMethod}
										value={method.paypal_min_withdraw_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>

								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Maximum amount</span>
									<input
										name="paypal_max_withdraw_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={onSubmitMethod}
										value={method.paypal_max_withdraw_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>
								<div className="user-data__toggle-wrapper left">
									<span className="user-data__toggle-text payment-methods__width">
										{!toggleWithPayPal ? 'Turned ON' : 'Turned OFF'}
									</span>
									<Toggle
										name="paypal_withdraw_limit_check"
										defaultChecked={!toggleWithPayPal}
										icons={false}
										onChange={(event: ChangeEvent) => {
											onSubmitToggle(event, !toggleWithPayPal);
											setToggleWithPayPal(!toggleWithPayPal);
										}}
									/>
								</div>
							</div>
						</div>
						<div className="payment-methods__row">
							<div className="payment-methods__box-left">
								<div className="payment-methods__svg">
									<StripeSvg />
								</div>
								<div className="payment-methods__name">Stripe</div>
							</div>
							<div className="payment-methods__box-right">
								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Minimum amount</span>
									<input
										name="stripe_min_withdraw_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={onSubmitMethod}
										value={method.stripe_min_withdraw_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>

								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Maximum amount</span>
									<input
										name="stripe_max_withdraw_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={onSubmitMethod}
										value={method.stripe_max_withdraw_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>
								<div className="user-data__toggle-wrapper left">
									<span className="user-data__toggle-text payment-methods__width">
										{!toggleWithStripe ? 'Turned ON' : 'Turned OFF'}
									</span>
									<Toggle
										name="stripe_withdraw_limit_check"
										defaultChecked={!toggleWithStripe}
										icons={false}
										onChange={(event: ChangeEvent) => {
											onSubmitToggle(event, !toggleWithStripe);
											setToggleWithStripe(!toggleWithStripe);
										}}
									/>
								</div>
							</div>
						</div> */}
						<div className="payment-methods__row">
							<div className="payment-methods__box-left">
								<div className="payment-methods__svg">
									<BankSvg />
								</div>
								<div className="payment-methods__name">Bank transfer</div>
							</div>
							<div className="payment-methods__box-right">
								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Minimum amount</span>
									<input
										name="base_fiat_min_withdraw_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={(event: KeyboardEvent) => {
											if (
												Number(method.base_fiat_max_withdraw_amount) <=
													Number(method.base_fiat_min_withdraw_amount) &&
												event.code === 'Enter'
											) {
												alert('The minimum amount cannot exceed the maximum amount.');
												return; // Якщо дата завершення менша за початкову, не оновлюємо стан
											}
											onSubmitMethod(event);
										}}
										value={method.base_fiat_min_withdraw_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>

								<label htmlFor="" className="payment-methods__label">
									<span className="payment-methods__percent-title">Maximum amount</span>
									<input
										name="base_fiat_max_withdraw_amount"
										onChange={handleInput}
										onBlur={defaultMethod}
										onKeyDown={(event: KeyboardEvent) => {
											if (
												Number(method.base_fiat_min_withdraw_amount) >=
													Number(method.base_fiat_max_withdraw_amount) &&
												event.code === 'Enter'
											) {
												alert('The minimum amount cannot exceed the maximum amount.');
												return; // Якщо дата завершення менша за початкову, не оновлюємо стан
											}
											onSubmitMethod(event);
										}}
										value={method.base_fiat_max_withdraw_amount}
										type="text"
										className="payment-methods__percent"
									/>
								</label>
								<div className="user-data__toggle-wrapper left">
									<span className="user-data__toggle-text payment-methods__width">
										{!toggleWithStripe ? 'Turned ON' : 'Turned OFF'}
									</span>
									<Toggle
										name="base_fiat_withdraw_limit_check"
										defaultChecked={!toggleWithStripe}
										icons={false}
										onChange={(event: ChangeEvent) => {
											onSubmitToggle(event, !toggleWithStripe);
											setToggleWithStripe(!toggleWithStripe);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
